/* eslint-disable import/no-anonymous-default-export */
const SERVERLESS_FN_URL = "https://private-workers-airtable-form.pages.dev/submit"

export default () => (
  <form action={SERVERLESS_FN_URL} method="POST" className="form-horizontal mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
  
<fieldset>

<legend>Form Name</legend>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtRef">Reference:</label>  
  <div class="col-md-2">
  <input id="txtRef" name="txtRef" type="text" placeholder="" class="form-control input-md" required="" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtAddressLine1">Address line 1</label>  
  <div class="col-md-6">
  <input id="txtAddressLine1" name="txtAddressLine1" type="text" placeholder="" class="form-control input-md" required="" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtAddressLine2">Address Line 2</label>  
  <div class="col-md-6">
  <input id="txtAddressLine2" name="txtAddressLine2" type="text" placeholder="" class="form-control input-md" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtTown">Town / City</label>  
  <div class="col-md-4">
  <input id="txtTown" name="txtTown" type="text" placeholder="" class="form-control input-md" required="" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtRegion">Region</label>  
  <div class="col-md-4">
  <input id="txtRegion" name="txtRegion" type="text" placeholder="" class="form-control input-md" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtPostcode">Postcode</label>  
  <div class="col-md-2">
  <input id="txtPostcode" name="txtPostcode" type="text" placeholder="" class="form-control input-md" required="" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtDeveloper">If new address, then please enter name of developer</label>  
  <div class="col-md-4">
  <input id="txtDeveloper" name="txtDeveloper" type="text" placeholder="" class="form-control input-md" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtAskingPrice">Asking Price</label>  
  <div class="col-md-4">
  <input id="txtAskingPrice" name="txtAskingPrice" type="text" placeholder="" class="form-control input-md" required="" />
  <span class="help-block">Numbers only please</span>  
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtSender">Requested By:</label>  
  <div class="col-md-4">
  <input id="txtSender" name="txtSender" type="text" placeholder="bob@example.com" class="form-control input-md" required="" />
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="txtNotes">Any other relevant information</label>
  <div class="col-md-4">                     
    <textarea class="form-control" id="txtNotes" name="txtNotes"></textarea>
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="ddlLocalAuthority">Local Authority</label>
  <div class="col-md-4">
    <select id="ddlLocalAuthority" name="ddlLocalAuthority" class="form-control">
      <option value="1">1 Council</option>
      <option value="2">2 Council</option>
    </select>
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="chkProducts">Search Products Required</label>
  <div class="col-md-4">
  <div class="checkbox">
    <label for="chkProducts-0">
      <input type="checkbox" name="chkProducts" id="chkProducts-0" value="1" />
      Regulated Local Authority Search
    </label>
	</div>
  <div class="checkbox">
    <label for="chkProducts-1">
      <input type="checkbox" name="chkProducts" id="chkProducts-1" value="2" />
      Groundsure Homebuyers
    </label>
	</div>
  <div class="checkbox">
    <label for="chkProducts-2">
      <input type="checkbox" name="chkProducts" id="chkProducts-2" value="" />
      Groundsure Avista
    </label>
	</div>
  <div class="checkbox">
    <label for="chkProducts-3">
      <input type="checkbox" name="chkProducts" id="chkProducts-3" value="" />
      CON29DW
    </label>
	</div>
  </div>
</div>
<div className="sm:col-span-2 sm:flex sm:justify-end">
      <button
        type="submit"
        className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
      >
        Submit
      </button>
    </div>
</fieldset>
</form>

)